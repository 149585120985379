html {
  font-size: 16px;
}

* {
  font-family: 'Helvetica';
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 960px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 13px;
  }
}

body {
  background: #fff !important;
  font-size: 1rem;
}

body * {
  color: #2c364f;
}

a {
  text-decoration: none;
}

/* scrollbar */

::-webkit-scrollbar {
  width: 5px;
  border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: rgba(44, 54, 79, 0.1);
  border-radius: 2.5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(44, 54, 79, 0.3);
  border-radius: 0;
}

/* buttons */

.icon-button__dark {
  fill: #2c364f !important;
  transition: all 0.3s ease 0s;
}

.icon-button__dark:hover {
  fill: rgba(44, 54, 79, 0.5) !important;
  cursor: pointer;
}

.icon-button__light {
  fill: #fff;
  transition: all 0.3s ease 0s;
}

.icon-button__light:hover {
  fill: #d1d8e1;
  cursor: pointer;
}

.icon-button__default {
  fill: rgba(44, 54, 79, 0.5);
  transition: all 0.3s ease 0s;
}

.icon-button__default:hover {
  fill: rgba(44, 54, 79, 0.7);
  cursor: pointer;
}

.action-button__default,
.action-button__default * {
  color: #2c364f;
  fill: #2c364f;
  transition: all 0.3s ease 0s;
}

.action-button__default:hover,
.action-button__default:hover * {
  color: rgba(44, 54, 79, 0.5);
  fill: rgba(44, 54, 79, 0.5);
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.icon-button__primary,
.action-button__primary,
.action-button__primary * {
  font-weight: bold;
  color: #ae3224;
  fill: #ae3224;
  transition: all 0.3s ease 0s;
}

.icon-button__primary:hover,
.action-button__primary:hover,
.action-button__primary:hover * {
  color: #85251b;
  fill: #85251b;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.see-all-button {
  margin-left: 1rem;
  transition: all 0.3s ease 0s;
}

/* nav */

.nav_link {
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.3s ease 0s;
}

.nav_link:hover {
  color: rgba(44, 54, 79, 0.5);
}

/* beadcrumbs */

.breadcrumbs-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.825rem 0;
}

.breadcrumb-button,
.breadcrumb-button * {
  font-size: 0.875rem;
  color: rgba(44, 54, 79, 0.5);
  fill: rgba(44, 54, 79, 0.5);
  transition: all 0.3s ease 0s;
}

.breadcrumb-button * {
  margin-right: 0.5rem;
}

.breadcrumb-button {
  margin-right: 1rem;
}

.breadcrumb-button:hover,
.breadcrumb-button:hover * {
  color: #2c364f;
  fill: #2c364f;
  cursor: pointer;
}

/* sections */

.section__light,
.section__dark {
  padding: 2rem 0;
  transition: all 0.3s ease 0s;
}

.section__dark {
  background: rgba(229, 229, 229, 0.2);
}

.section_title-wrapper {
  margin: 0 0 2rem 0;
  display: flex;
  align-items: center;
}

.section_title {
  margin: 0;
  display: inline-block;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: rgba(44, 54, 79, 0.5);
}

/* auth / profile */

.auth-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 9rem);
}

.auth_text-main {
  font-size: 1rem;
  line-height: 1.25rem;
}

.auth_text-bold {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.auth_text-secondary {
  color: rgba(44, 54, 79, 0.5);
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 3.5rem;
}

.auth_link {
  font-weight: bold;
  font-size: inherit;
  line-height: inherit;
  text-decoration: underline;
  color: #ae3224;
  transition: all 0.3s ease 0s;
}

.auth_link:hover {
  color: #85251b;
  cursor: pointer;
}

.auth_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;
}

.auth_helper-button,
.auth_helper-text {
  width: 100%;
  text-align: right;
  color: rgba(44, 54, 79, 0.5);
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition: all 0.3s ease 0s;
}

.auth_helper-button:hover {
  color: #2c364f;
  cursor: pointer;
}

.auth_checkbox-label {
  font-weight: 0.875rem;
  line-height: 1.25rem;
}

.auth_checkbox-wrapper {
  width: 100%;
  margin-top: 1.5rem;
  padding-left: 2px;
}

.auth_social-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem auto;
}

.auth_social-button {
  fill: rgba(44, 54, 79, 0.5);
  transition: all 0.3s ease 0s;
  margin: 0 0.75rem;
}

.auth_social-button:hover {
  cursor: pointer;
  fill: #ae3224;
}

.profile_title {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 2rem auto 1.25rem auto;
}

.profile_subtitle {
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0 0 1.5rem 0;
}

/* hub card */

.card_gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(44, 54, 79, 0.1) 0%, #2c364f 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
}

.play-icon-wrapper:hover {
  opacity: 0.7;
}

/* article card */

.article-card-wrapper {
  display: flex;
}

.article-card-wrapper:hover {
  cursor: pointer;
}

.article-card_info-wrapper,
.article-card_label-wrapper {
  display: flex;
  align-items: center;
}

.article-card_info-wrapper {
  margin: 0.75rem 0 0 0;
}
.article-card_label-wrapper {
  margin: 0;
}

.article-card__text-wrapper {
  max-width: 100%;
  overflow-wrap: break-word;
}

.article-card__text-wrapper * {
  font-size: 0.75rem;
  overflow-wrap: break-word;
}

.article-card_label,
.article-card_text-secondary {
  font-size: 0.75rem;
}

.article-card_text-secondary {
  color: rgba(44, 54, 79, 0.5);
}

.article-card_title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.article-card_title-wrapper {
  margin-bottom: 0.5rem;
}

.article-card_text {
  font-size: 0.875rem;
  margin: 0 0 1rem 0;
  white-space: pre-line;
}

.article-card_button {
  color: #2c364f;
  fill: #2c364f;
  font-size: 0.75rem;
  vertical-align: middle;
}

.article-card_button {
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.article-card-wrapper:hover .article-card_button {
  opacity: 1;
}

.article-card_button:hover {
  color: rgba(44, 54, 79, 0.5);
  fill: rgba(44, 54, 79, 0.5);
  cursor: pointer;
}

.article-card_button * {
  fill: inherit;
  height: 0.75rem;
  width: 0.75rem;
  vertical-align: middle;
}

.articles-scroll-wrapper {
  padding: 2rem;
  max-height: 40rem;
  overflow: auto;
}

/* article */

.content-wrapper p,
.content-wrapper pre {
  font-size: 1.25rem;
  line-height: 2rem;
  margin: 1rem 0;
  white-space: pre-line;
}

.content-wrapper ul {
  list-style: url('./media/icons/list-bullet.svg');
  padding-left: 1rem;
}

.content-wrapper li {
  padding-left: 0.625rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.content-wrapper h1,
.content-wrapper h2,
.content-wrapper h3 {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1.5rem 0;
}

.content-wrapper table {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;
}

.content-wrapper th,
.content-wrapper td {
  padding: 0.25rem 1rem;
  border: 1px solid #000;
}

.article_info-wrapper,
.article_label-wrapper {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  white-space: nowrap;
}

.article_label,
.article_text-secondary {
  font-size: 1rem;
  display: inline-block;
}

.article_text-secondary {
  color: rgba(44, 54, 79, 0.5);
}

.article_title {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0 0 1.75rem 0;
}

.article_subtitle {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1.5rem 0;
}

.article_text {
  font-size: 1.25rem;
  line-height: 2rem;
  margin: 1rem 0;
  white-space: pre-line;
}

.article_image {
  display: block;
  width: 100%;
  max-width: 100%;
}

.article_button {
  color: #2c364f;
  fill: #2c364f;
  font-size: 1rem;
  vertical-align: middle;
}

.article_button:hover {
  color: rgba(44, 54, 79, 0.5);
  fill: rgba(44, 54, 79, 0.5);
  cursor: pointer;
}

.article_button * {
  fill: inherit;
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
}

.article_link-wrapper {
  margin: 1rem 0;
}

.article_link {
  font-weight: bold;
  font-size: 1rem;
  line-height: 2rem;
  color: rgba(44, 54, 79, 0.5);
}

.article_link:hover {
  color: #2c364f;
  cursor: pointer;
}

.article_list {
  list-style: url('./media/icons/list-bullet.svg');
  padding-left: 1rem;
}

.article_list-item {
  padding-left: 0.625rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

/* chip */

.chip-icon-wrapper {
  width: 2.5rem;
}

.chip-icon {
  margin: 0 0.75rem 0 1rem !important;
  width: 0.75rem !important;
  max-height: 0.75rem !important;
  min-width: 0.75rem !important;
}

/* footer */

.footer {
  top: auto;
  bottom: 0;
}

.footer * {
  font-size: 1rem;
}

/* page */

.page-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-title {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin: 1rem auto;
}

.home-page-button {
  color: rgba(44, 54, 79, 0.5);
  fill: rgba(44, 54, 79, 0.5);
  font-size: 0.875rem;
  margin: 0 auto 1rem;
  transition: all 0.3s ease 0s;
}

.home-page-button:hover {
  color: rgba(44, 54, 79, 1);
  fill: rgba(44, 54, 79, 1);
  cursor: pointer;
}

.home-page-button:active {
  color: rgba(44, 54, 79, 0.7);
  fill: rgba(44, 54, 79, 0.7);
  cursor: pointer;
}

.home-page-button * {
  color: inherit;
  fill: inherit;
  width: 0.625rem;
  height: 0.625rem;
}

.grid-button {
  margin-left: 1rem;
  fill: #2c364f;
  opacity: 0.5;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.grid-button:hover {
  opacity: 1;
}

.grid-button__selected {
  opacity: 1;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

/* tabs */

.tab-wrapper {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  white-space: nowrap;
}

.tab-wrapper * {
  white-space: nowrap;
}

.tab-icon-wrapper {
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tab-icon-wrapper * {
  fill: #2c364f;
}

/* filter */

.filter_section-title {
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #2c364f !important;
  opacity: 1;
  white-space: nowrap;
}

.filter_option-title {
  white-space: nowrap;
}

.filter_section-label {
  font-weight: 500;
  margin: 0 1rem 1rem 0;
}

.filter_option-label {
  font-weight: 300;
  margin: 0 1rem 1rem 0;
  white-space: nowrap;
}

/* media player */

video {
  display: block;
}

video::-webkit-media-controls {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}

.video_gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background: linear-gradient(180deg, rgba(44, 54, 79, 0.1) 0%, rgba(44, 54, 79, 0.5) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-icon-wrapper:hover {
  opacity: 0.8;
  cursor: pointer;
}

.hide {
  display: none;
}

#video-player {
  position: relative;
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
  margin-inline-start: 0;
  margin-inline-end: 0;
  display: flex;
  align-items: center;
}

#audio-player {
  width: 100%;
}

#video-controls {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  padding: 0;
  /* visibility: hidden;
  opacity: 0; */
  transition: all 0.3s ease-out 0s;
  /* z-index: 2147483647; */
  display: flex;
  flex-direction: column;
}

#video-player:hover > #video-controls {
  /* visibility: visible; */
  opacity: 1;
  border-radius: 5px;
  /* overflow: hidden; */
}

#video-player video {
  border-radius: 5px;
  overflow: hidden;
}

.control-btn {
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
  background: none;
  margin: 0 7px;
}

.control-btn:hover * {
  opacity: 0.8;
}

.control-btn * {
  fill: #fff;
  /* width: 25px;
  height: 25px; */
  transition: all 0.3s ease-out 0s;
}

.media-time {
  color: #fff;
  font-size: 1rem;
  margin: 0 0.5rem;
  white-space: nowrap;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #video-player input[type='range'].video_progress {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    margin: 0;
    transition: background 450ms ease-in;
  }

  #video-player input[type='range'].video_progress:focus {
    outline: none;
  }

  #video-player input[type='range'].video_progress::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    border-radius: 0;
    border: 0px solid rgba(1, 1, 1, 0);
    transition: all 0.3s ease-out 0s;
  }

  #video-player input[type='range'].video_progress::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid rgba(0, 0, 0, 0);
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -2.5px;
    visibility: hidden;
    opacity: 0;
  }

  #video-player input[type='range'].video_progress:hover::-webkit-slider-thumb {
    visibility: visible;
    opacity: 1;
  }

  #audio-player input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    margin: 0;
    transition: background 450ms ease-in;
  }

  #audio-player input[type='range']:focus {
    outline: none;
  }

  #audio-player input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    border-radius: 0;
    border: 0px solid rgba(1, 1, 1, 0);
    transition: all 0.3s ease-out 0s;
  }

  #audio-player input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid rgba(0, 0, 0, 0);
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }

  /* input[type='range'].video_progress:focus::-webkit-slider-runnable-track {
    background: #d1d8e1;
  } */
}

.video_volume-progress {
  transition: all 0.3s ease-out 0s;
}

.video_volume-wrapper {
  width: 150px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 15px;
  margin-right: 50px;
}

.video_buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.5rem;
}

#video-player input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border-radius: 0;
  border: 0px solid rgba(1, 1, 1, 0);
}

#video-player input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  margin-top: -5px;
}

figure[data-fullscreen='true'] {
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

figure[data-fullscreen='true'] video {
  height: auto;
  border-radius: 0 !important;
}

figure[data-fullscreen='true'] .video_gradient {
  border-radius: 0 !important;
}

figure[data-fullscreen='true'] #video-controls {
  bottom: 2%;
}

#audio-player input[type='range']::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border-radius: 0;
  border: 0px solid rgba(1, 1, 1, 0);
}

#audio-player input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  margin-top: -4px;
}

/* input[type='range'].video_progress::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type='range'].video_progress::-ms-fill-lower {
  background: red;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 0;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}

input[type='range'].video_progress::-ms-fill-upper {
  background: red;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 3.8px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}

input[type='range'].video_progress::-ms-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 5px;
  width: 5px;
  border-radius: 0;
  background: #eeeeee;
  cursor: pointer;
}

input[type='range'].video_progress:focus::-ms-fill-lower {
  background: red;
}

input[type='range'].video_progress:focus::-ms-fill-upper {
  background: red;
} */

/* media */

@media (max-width: 960px) {
  /* article */

  .article_title {
    font-size: 2rem;
  }

  /* page */

  .page-title {
    font-size: 2rem;
    line-height: 2rem;
  }
}

@media (max-width: 600px) {
  /* footer */

  .footer * {
    font-size: 0.625rem;
  }

  /* page */

  .page-title {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  /* auth */

  .auth-wrapper {
    min-height: calc(100vh - 7.5rem);
  }

  /* tabs */

  .tab-wrapper {
    padding-right: 0;
  }

  .tab-icon-wrapper {
    justify-content: center;
  }

  /* article */

  .article_info-wrapper,
  .article_label-wrapper {
    margin: 0.75rem 0;
    white-space: nowrap;
  }

  .article_label,
  .article_text-secondary {
    font-size: 0.875rem;
    display: inline-block;
    white-space: nowrap;
  }

  .article_title {
    font-size: 1.5rem;
    margin: 0 0 1.5rem 0;
  }

  .article_subtitle {
    font-size: 1rem;
    margin: 1rem 0;
  }

  .article_text {
    font-size: 1rem;
  }

  .article_button {
    font-size: 0.875rem;
    white-space: nowrap;
  }

  .article_button * {
    height: 0.875rem;
    width: 0.875rem;
  }

  .article_list-item {
    font-size: 1rem;
  }

  .article_link {
    font-size: 0.875rem;
  }

  /* breadcrumbs */

  .breadcrumbs-wrapper {
    justify-content: center;
  }
}
